<template>
  <div>
    <div id="skilldiv" style="height: 5.3rem">
      <span v-if="conformIshave" style="color:red;font-size:.36rem;">暂无该数据</span>
      <canvas id="skillid"></canvas>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        canvas:"",
        ctx:"",
        canvasCenter:"",
        dpr:window.devicePixelRatio,
        SX:'',
        SY:'',
        SY2:"",
        Bindex:"",
        objSkill:[],
        colorBoolean:true,
        colorArray:[["#34ABE5","#3CD4E5"],["#4234FF","#377DFF"]],
        event:"",
        allmsg:[],
        conformIshave:false
    };
  },
  mounted() {
    
  },
  watch:{

  },
  methods: {
    childrenMeth(msg){
      console.log(msg);
      this.allmsg = msg;
      this.skillmeth();
    },
    skillmeth() {
      this.canvas = document.getElementById("skillid");
      this.ctx = this.canvas.getContext("2d");
      this.canvas.width =
        document.getElementById("skilldiv").offsetWidth * this.dpr;
      this.canvas.height =
        document.getElementById("skilldiv").offsetHeight * this.dpr;
      this.canvas.style.width =
        document.getElementById("skilldiv").offsetWidth + "px";
      this.canvas.style.height =
        document.getElementById("skilldiv").offsetHeight + "px";
      this.ctx.scale(this.dpr, this.dpr);
      this.canvasCenter = {
        x: this.canvas.width / (2 * this.dpr),
        y: this.canvas.height / (2 * this.dpr),
      };
      
      this.SX = 20;
      this.SY = 10;
      this.SY2 = 65;
      this.Bindex = 5;

      let maxDrawValue = 58,maxValue='',anValue="",arr=[];
      if(this.allmsg.length>0){
          maxValue = this.allmsg[0].tag_weight;
          anValue = (maxDrawValue/maxValue).toFixed(2);
      }else{
        this.conformIshave = true;
      }
      this.allmsg.forEach(e=>{
        console.log(anValue);
        arr.push({
          name:e.tag_name,
          value:((e.tag_weight)*anValue).toFixed(2)
        })
      })
      console.log(arr);
      this.objSkill = arr;

      this.drawString();
      
      this.drawTo();
      
    },
    drawTo(){
        let y = this.SY;
        for(let i=0;i<this.objSkill.length;i++){
            y+=this.Bindex;
            this.drawBar(this.objSkill[i],y,this.colorBoolean?this.colorArray[0]:this.colorArray[1]);
             if(this.ctx.isPointInPath(this.event.offsetX*this.dpr ,this.event.offsetY*this.dpr)){
                console.log("click");
            }
            y+=this.Bindex;
            this.colorBoolean = !this.colorBoolean;
        }
    },
    pxToRpx(width) {
      return (window.innerWidth / 100) * width;
    },
    drawString(){
        this.ctx.beginPath();
        this.ctx.lineWidth = 1/this.dpr;
        this.ctx.moveTo(this.pxToRpx(this.SX),this.pxToRpx(this.SY));
        this.ctx.lineTo(this.pxToRpx(this.SX),this.pxToRpx(this.SY2));
        this.ctx.strokeStyle="#C0C4CC";
        this.ctx.stroke();
    },
    drawBar(obj,y,color){
        this.ctx.fillStyle = "#303133";
        this.ctx.textAlign = "right";
        this.ctx.textBaseline = "middle";
        this.ctx.font=""+this.pxToRpx(3)+"px self";
        this.ctx.fillText(obj.name,this.pxToRpx(this.SX-2.5),this.pxToRpx(y+2.5));
        this.ctx.textAlign = "left";
        this.ctx.fillText(obj.value,this.pxToRpx(obj.value)+this.pxToRpx(this.SX+2.5),this.pxToRpx(y+2.5));
        
        let gradient = this.ctx.createLinearGradient(0,0,this.pxToRpx(obj.value),0);
        gradient.addColorStop(0, color[0]);
        gradient.addColorStop(1, color[1]);
        this.ctx.fillStyle = gradient;
        this.ctx.fillRect(this.pxToRpx(this.SX),this.pxToRpx(y),this.pxToRpx(obj.value),this.pxToRpx(5));
    }
  },
};
</script>
<style scoped>
</style>