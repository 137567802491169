<template>
  <div>
    <div @childMeth="childMeth"></div>
    <van-dialog
      @confirm="confirm"
      v-model="show"
      :title="
        '购买' +
        (type == 1 ? '能力分析报告' : type == 2 ? '胜任力匹配报告' : '')
      "
      show-cancel-button
    >
      <!-- <div class="moclass"></div> -->
      <div class="yueclass">使用余额<span style="color:#ff641b;">3</span></div>
      <!-- <h5>
        下图是胜任力分析<span style="color: #ee0a24">样例</span>(仅供参考)
      </h5>
      <div class="yangli">
          <img v-if="type==1" src="../../assets/resumeEvaluation.png" alt="" />
        <img v-if="type==2" src="../../assets/resumematch.png" alt="" />
      </div> -->
    </van-dialog>
  </div>
</template>
<script>
import { Dialog, Toast } from "vant";
export default {
  data() {
    return {
      allmsg: "",
      show: false,
      type: "",
      id: "",
    };
  },
  methods: {
    childMeth(msg, id, type,evalmsg) {
      this.allmsg = msg;
      this.type = type;
      this.id = id;
      //判断报告是否购买
      if (!(this.$route.query.is_download == 10)) {
        Dialog.confirm({
          title: "提醒",
          message: "该简历还未下载，是否下载简历？",
        })
          .then(() => {
            this.$router.push({
              path:"evalpay",
              query:{
                msg:evalmsg,
                backIndex:-2
              }
            })
          })
          .catch(() => {
            // on cancel
          });
        return;
      }
      if (msg.isPay == 0) {
        this.show = true;
      }
    },
    confirm() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "请稍后...",
      });
      this.$http
        .post("/firm/v1/talent_center/payResumeReport", {
          reqType: "resume",
          type: this.type, //1:能力 2:胜任力
          resumeId: this.id,
        })
        .then((res) => {
          Toast.clear();
          this.$toast("支付成功");
          setTimeout(() => {
            this.$router.go(0);
          }, 500);
        });
    },
  },
};
</script>
<style scoped>
.yueclass {
  font-size: 0.33rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.4rem;
  margin-top: 0.22rem;
  margin-bottom: 0.56rem;
  text-align: center;
  margin-top: .4rem;
}
.moclass {
  font-size: 0.72rem;
  font-weight: 500;
  color: #ff641b;
  line-height: 0.48rem;
  text-align: center;
  margin-top: 0.66rem;
}
h3,
h5 {
  text-align: center;
}
.yangli {
  height: 5rem;
  overflow-y: auto;
  margin: 0.2rem;
}
.yangli > img {
  width: 100%;
}
</style>