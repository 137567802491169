<template>
  <div class="allmsgclass">
    <navigation msg="能力分析"></navigation>
    <div class="ckclass">此报告仅供参考，不作为入离职依据</div>
    <div class="topmsgclass">
      <div class="allimg">
        <img
          v-if="allmsg.sex == '女'"
          class="imgone"
          src="../../assets/user_2.png"
          alt=""
        />
        <img v-else class="imgone" src="../../assets/user_1.png" alt="" />
        <img class="imgtwo" src="../../assets/shiming.png" alt="" />
      </div>
      <div class="m1">{{ allmsg.name }}</div>
      <div class="m2">{{ allmsg.job }}•{{ allmsg.company }}</div>
      <div class="heng"></div>
      <div class="xinc">
        <div></div>
        <div>薪酬预测</div>
      </div>
      <div class="allmoney">
        <div class="money1">
          <div>预估薪资：</div>
          <div>{{ allmsg.estimated_salary }}</div>
        </div>
        <div class="money2">
          <div>期望薪资：</div>
          <div>{{ allmsg.expect_salary }}</div>
        </div>
        <div class="money3">
          <div>实际薪资：</div>
          <div>{{ allmsg.work_salary }}</div>
        </div>
      </div>
      <div class="zhuc">
        注：预估薪资是机器学习模型预测的值，期望薪资和实际薪资是简历实际填写的值（0表示无填写）。
      </div>
    </div>
    <div :style="lock ? '' : 'filter:blur(.15rem);'">
      <div class="menuclass">
        <div class="xinc">
          <div></div>
          <div>智能标签</div>
        </div>
        <div
          id="cloudid"
          :style="'position: relative;height:265px;width:' + cloudX + 'px'"
        ></div>
      </div>
      <div class="menuclass">
        <div class="xinc">
          <div></div>
          <div>职位名称符合度</div>
        </div>
        <div id="container" style="height: 5.3rem"></div>
      </div>
      <div class="menuclass">
        <div class="xinc">
          <div></div>
          <div>职位类型符合度</div>
        </div>
        <div id="mains" style="height: 5.3rem"></div>
      </div>
      <div class="menuclass">
        <div ref="refid" id="thexinid">
          <div class="xinc">
            <div></div>
            <div>
              行业领域符合度<span v-if="conformIshave" style="color: red"
                >(暂无该数据)</span
              >
            </div>
          </div>
          <div id="conformitydiv" style="height: 5.3rem">
            <canvas id="conformity"></canvas>
          </div>
          <div class="objClass" v-for="(item, index) in objArray" :key="index">
            <div :style="'background:' + item.backcolor"></div>
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="menuclass">
        <div class="xinc">
          <div></div>
          <div>技能标签</div>
        </div>
        <skillcanvas ref="skillid"></skillcanvas>
      </div>
    </div>
    <div v-if="!lock" class="jiesuo">
      <div>
        <img src="../../assets/resumematch_lock.png" alt="" />
      </div>
      <div>购买后即可查看所有分析</div>
      <div>
        <div @click="buymeth" class="buy">立即购买</div>
      </div>
    </div>
    <ispayResumeMatch ref="matchid"></ispayResumeMatch>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { Dialog } from "vant";
import jsCloud from "../../../util/jsCloud";
import skillcanvas from "../../components/skillcanvas.vue";
import ispayResumeMatch from "../../components/ispayResumeMatch/index.vue";
export default {
  components: {
    skillcanvas,
    ispayResumeMatch,
  },
  data() {
    return {
      cloudX: parseInt((window.innerWidth / 100) * 75),
      cloudY: "",
      ctx: "",
      oldR: 1,
      onelength: "",
      event: "",
      canvas: "",
      canvasCenter: {},
      maxR: 200,
      dpr: window.devicePixelRatio,
      objArray: [],
      colorArray: [
        "rgb(111,172,237)",
        "rgb(116,183,222)",
        "rgb(122,194,206)",
        "rgb(125,204,191)",
        "rgb(130,214,176)",
      ],
      comtipclass:
        " visibility: hidden;transform: translateX(-110%);box-shadow: rgb(0 0 0 / 20%) 1px 2px 10px;position: absolute;background:#ffffff;width:100px;height:100px；white-space: nowrap;padding:.2rem;font-size: .3rem;color:#666;font-weight: 400; margin-left: 2px;text-align:center;transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s,left .4s,top .4s;",
      allmsg: {},
      conformIshave: false,
      lock: false,
      ispay: "-1",
    };
  },
  beforeCreate() {},
  mounted() {
    //如果是人才库进入，不需要请求接口
    if (this.$route.query.resumeassistant == 1) {
      console.log(JSON.parse(this.$route.query.allmsg));
      this.allmsg = JSON.parse(this.$route.query.allmsg);
      this.allmsg.assessment = JSON.parse(this.allmsg.assessment);
      this.lock = true;
      this.jsCloudMeth();
      this.labelmeth();
      this.piedmeth();
      this.conformitymeth();
      this.$refs.skillid.childrenMeth(
        this.allmsg.assessment.skills_tags.splice(0, 5)
      );
    } else {
      // 获取数据
      this.getmsg();
    }
  },
  methods: {
    buymeth() {
      if (this.ispay == "-1") {
        Dialog.alert({
          title: "提醒",
          message: "该简历没有内容，不建议购买。",
        }).then(() => {
          // on close
        });
        return;
      }
      this.$refs.matchid.childMeth(
        this.allmsg,
        atob(this.$route.query.id),
        1,
        this.$route.query.evalmsg
      );
    },
    getmsg() {
      this.$http
        .post("/firm/v1/talent_center/getReportInfo", {
          reqType: "resume",
          resumeId: atob(this.$route.query.id),
        })
        .then((res) => {
          this.allmsg = JSON.parse(res.data).data;
          this.lock = this.ispay = this.allmsg.isPay;
          // 基于准备好的dom，初始化echarts实例
          this.jsCloudMeth();
          this.labelmeth();
          this.piedmeth();
          this.conformitymeth();
          this.$refs.skillid.childrenMeth(
            this.allmsg.assessment.skills_tags.splice(0, 5)
          );
        });
    },
    jsCloudMeth() {
      let arr = [];
      this.allmsg.assessment.skills_tags.forEach((e) => {
        arr.push({
          text: e.tag_name,
          weight: e.tag_weight,
        });
      });
      var word_array = arr;
      jsCloud(document.getElementById("cloudid"), word_array);
    },
    piedmeth() {
      var chartDom = document.getElementById("mains");
      var myChart = echarts.init(chartDom);
      var option;

      let arr = this.allmsg.assessment.pos_types.splice(0, 3);
      let arr2 = [],
        index = 0,
        colorarr = [
          "rgba(111,172,237,1)",
          "rgba(130,214,176,1)",
          "rgba(121,194,206,1)",
        ];
      arr.forEach((e) => {
        arr2.push({
          value: e.tag_weight.toFixed(2),
          name: e.tag_name,
          itemStyle: {
            color: colorarr[index],
          },
        });
        index++;
      });

      option = {
        backgroundColor: "#ffffff",
        title: {
          text: "",
          left: "center",
          top: 20,
          textStyle: {
            color: "#ccc",
          },
        },

        tooltip: {
          trigger: "item",
        },

        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        series: [
          {
            name: "详细信息",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: arr2,
            roseType: "radius",
            label: {
              color: "rgba(150,141,145,1)",
            },
            labelLine: {
              lineStyle: {
                color: "rgba(150,141,145,1)",
              },
              smooth: 0.2,
              length: 10,
              length2: 20,
            },
            itemStyle: {
              color: "#c23531",
              shadowBlur: 200,
              shadowColor: "rgba(0, 0, 0, .1)",
            },

            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    labelmeth() {
      var myChart = new echarts.init(document.getElementById("container"));
      let pos_tags = this.allmsg.assessment.pos_tags;
      let arr2 = pos_tags.splice(0, 3);
      let arrname = [],
        arrvalue = [];
      arr2.forEach((e) => {
        arrname.push(e.tag_name);
        arrvalue.push(e.tag_weight.toFixed(2));
      });
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "none",
          },
          formatter: function (params) {
            return params[0].name + ": " + params[0].value;
          },
        },

        xAxis: {
          data: arrname,
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: {
            color: "#545454",
            rotate: 45,
          },
        },
        yAxis: {
          splitLine: { show: false },
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: { show: false },
        },
        color: ["#5AAEF3"],
        series: [
          {
            name: "hill",
            type: "pictorialBar",
            barCategoryGap: "-130%",
            // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
            symbol:
              "path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z",
            itemStyle: {
              opacity: 0.5,
            },
            label: {
              show: true,
              position: "top",
              formatter: "{c}",
              fontSize: 16,
              color: "#000000",
            },
            emphasis: {
              itemStyle: {
                opacity: 1,
              },
            },
            data: arrvalue,
            z: 10,
          },
        ],
      };

      option && myChart.setOption(option);
    },
    //准备轮廓
    drawcon(ctx, objArray, length) {
      ctx.beginPath();
      ctx.moveTo(this.canvasCenter.x, this.canvasCenter.y);
      ctx.arc(
        this.canvasCenter.x,
        this.canvasCenter.y,
        objArray.value,
        this.oldR * Math.PI,
        (1 + length) * Math.PI,
        false
      );
      this.oldR += this.onelength;
    },
    //上色
    drawconTos(objArray) {
      this.ctx.fillStyle = objArray.backcolor;
      this.ctx.fill();
    },
    fileterDraw(objArray, self) {
      let length = 0;
      this.onelength = 0.5 / objArray.length;
      for (let i = 0; i < objArray.length; i++) {
        length += this.onelength;
        self.drawcon(self.ctx, objArray[i], length);
        if (
          this.ctx.isPointInPath(
            this.event.offsetX * this.dpr,
            this.event.offsetY * this.dpr
          )
        ) {
          this.ctx.globalAlpha = 0.5;
          this.drawconTos(objArray[i]);
          setTimeout(() => {
            //提示框
            this.tipmsg(
              this.event.offsetX + 10,
              this.event.offsetY - 10,
              objArray[i],
              this.event.pageX,
              this.event.pageY
            );
          }, 10);
        } else {
          this.condiv.style =
            this.condiv.style.cssText + " visibility: hidden;";
          this.ctx.globalAlpha = 1;
          this.drawconTos(objArray[i]);
        }
      }

      //画虚线
      let Oal = 0;
      let Oin = this.maxR / 7;
      this.ctx.globalAlpha = 1;
      for (let i = 0; i < 7; i++) {
        Oal += Oin;
        this.dottedmeth(Oal);
      }
    },
    //画虚线
    dottedmeth(R) {
      this.ctx.beginPath();
      this.ctx.arc(
        this.canvasCenter.x,
        this.canvasCenter.y,
        R,
        1 * Math.PI,
        1.5 * Math.PI,
        false
      );
      this.ctx.strokeStyle = "rgb(227,230,233)";
      this.ctx.setLineDash([5, 5, 5]);
      this.ctx.stroke();
      this.ctx.closePath();
    },
    //角度转换
    pxToRpx(width) {
      return (window.innerWidth / 100) * width;
    },
    //提示框
    tipmsg(x, y, objArray, pageX, pageY) {
      this.condiv.style =
        this.condiv.style.cssText +
        ` left:${pageX}px;top:${pageY}px; visibility: visible;border:1px solid ${objArray.backcolor}`;
      this.condiv.innerHTML = objArray.name + "：" + objArray.value;
    },
    //创建提示框
    creattip() {
      this.condiv = document.createElement("div");
      this.condiv.id = "ppp";
      this.condiv.style = this.comtipclass;
      this.$refs.refid.appendChild(this.condiv);
      document.getElementById("ppp").style =
        document.getElementById("ppp").style.cssText;
    },
    // 画领域符合度（弧度）
    conformitymeth() {
      this.creattip();
      this.canvas = document.getElementById("conformity");
      this.ctx = this.canvas.getContext("2d");
      this.canvas.width =
        document.getElementById("conformitydiv").offsetWidth * this.dpr;
      this.canvas.height =
        document.getElementById("conformitydiv").offsetHeight * this.dpr;
      this.canvas.style.width =
        document.getElementById("conformitydiv").offsetWidth + "px";
      this.canvas.style.height =
        document.getElementById("conformitydiv").offsetHeight + "px";
      this.ctx.scale(this.dpr, this.dpr);
      this.canvasCenter = {
        x: this.canvas.width / (2 * this.dpr) + (window.innerWidth / 100) * 20,
        y: this.canvas.height / (2 * this.dpr) + (window.innerWidth / 100) * 30,
      };

      let arr = this.allmsg.assessment.industries.splice(0, 5),
        arr2 = [],
        index = 0;
      let maxDrawValue = 60,
        maxValue = "",
        anValue = "";
      if (arr.length > 0) {
        maxValue = arr[0].tag_weight;
        anValue = (maxDrawValue / maxValue).toFixed(2);
      } else {
        this.conformIshave = true;
      }
      arr.forEach((e) => {
        arr2.push({
          name: e.tag_name,
          value: this.pxToRpx(anValue * e.tag_weight),
          backcolor: this.colorArray[index],
        });
        index++;
      });
      let objArray = (this.objArray = arr2);
      let self = this;
      this.fileterDraw(objArray, self);
      let conformitydivs = document.getElementById("conformity");

      conformitydivs.addEventListener("touchmove", function (e) {
        self.todraw(e, self, objArray);
      });
      conformitydivs.addEventListener("touchstart", function (e) {
        self.todraw(e, self, objArray);
      });
    },
    todraw(e, self, objArray) {
      let a = {
        offsetX: parseInt(
          e.changedTouches[0].pageX - e.changedTouches[0].target.offsetLeft
        ),
        offsetY: parseInt(
          e.changedTouches[0].pageY - e.changedTouches[0].target.offsetTop
        ),
        pageX: e.changedTouches[0].pageX,
        pageY: e.changedTouches[0].pageY,
      };
      self.ctx.clearRect(0, 0, self.canvas.width, self.canvas.height);
      self.oldR = 1;
      self.event = a;
      self.fileterDraw(objArray, self);
    },
  },
};
</script>
<style scoped>
.ckclass {
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.33rem;
  background: #fe9158;
  text-align: center;
  padding: 0.14rem 0;
}
.buy {
  display: inline-block;
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.28rem;
  padding: 0.26rem 0.58rem;
  background: linear-gradient(90deg, #ff8d35 0%, #ff5f18 100%);
  border-radius: 0.08rem;
  margin-top: 0.5rem;
}
.jiesuo > div {
  text-align: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.28rem;
}
.jiesuo {
  margin-top: 0.3rem;
  position: fixed;
  top: 9rem;
  height: 100%;
  width: 100%;
  opacity: 0.98;
}
.jiesuo img {
  width: 0.62rem;
  margin-bottom: 0.34rem;
}
.objClass {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
  font-weight: 400;
  color: #545454;
  line-height: 0.34rem;
  margin-left: 20%;
  margin-bottom: 0.2rem;
}
.objClass > :first-child {
  width: 0.12rem;
  height: 0.12rem;
  border-radius: 50%;
  margin-right: 0.16rem;
}
.allmsgclass >>> .w10 {
  font-size: 0.4rem;
  color: rgb(104, 200, 247);
}
.allmsgclass >>> .w9 {
  font-size: 0.35rem;
  color: rgb(87, 152, 218);
}
.allmsgclass >>> .w8 {
  font-size: 0.35rem;
  color: rgb(87, 152, 218);
}
.allmsgclass >>> .w7 {
  font-size: 0.33rem;
  color: rgb(160, 194, 221);
}
.allmsgclass >>> .w6 {
  font-size: 0.33rem;
  color: rgb(160, 194, 221);
}
.allmsgclass >>> .w5 {
  font-size: 0.3rem;
  color: rgb(194, 160, 196);
}
.allmsgclass >>> .w4 {
  font-size: 0.3rem;
  color: rgb(194, 160, 196);
}

.allmsgclass >>> .w3 {
  font-size: 0.27rem;
  color: rgb(199, 226, 246);
}
.allmsgclass >>> .w2 {
  font-size: 0.27rem;
  color: rgb(199, 226, 246);
}
.allmsgclass >>> .w1 {
  font-size: 0.24rem;
  color: rgb(216, 227, 234);
}
.allmsgclass {
  overflow: hidden;
}
.menuclass {
  padding: 0.34rem 0.38rem;
  margin: 0.18rem 0;
  background-color: #ffffff;
}
.zhuc {
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.44rem;
}
.allmoney {
  margin: 0.3rem 0 0.28rem 0;
}
.money1,
.money2,
.money3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.money2 {
  margin-top: 0.16rem;
  margin-bottom: 0.14rem;
}
.money1 > :first-child {
  font-size: 0.32rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
}
.money1 > :nth-child(2) {
  font-size: 0.32rem;
  font-weight: 600;
  color: rgba(69, 113, 208, 0.9);
  line-height: 0.48rem;
}
.money2 > :first-child {
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
}
.money2 > :last-child {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
}
.money3 > :first-child {
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
}
.money3 > :last-child {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.48rem;
}
.xinc {
  display: flex;
  align-items: center;
  font-size: 0.36rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
}
.xinc > :first-child {
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 50%;
  background-color: #ec5d33;
  margin-right: 0.24rem;
}
.heng {
  margin-top: 0.36rem;
  margin-bottom: 0.32rem;
  background-color: #eeeff1;
  height: 1px;
}
.m1 {
  font-size: 0.4rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
  margin-top: 0.4rem;
  text-align: center;
}
.m2 {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.33rem;
  margin-top: 0.08rem;
  text-align: center;
}
.allimg {
  width: 1.06rem;
  height: 1.06rem;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
}
.imgone {
  width: 1.06rem;
  height: 1.06rem;
}
.imgtwo {
  position: absolute;
  width: 1.08rem;
  bottom: -0.2rem;
  left: 50%;
  transform: translateX(-50%);
}
.topmsgclass {
  background-color: #ffffff;
  padding: 0.38rem;
}
</style>